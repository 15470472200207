import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueCookies from 'vue-cookies'

import App from './App.vue'
import router from './router'
import i18n, { setupI18n } from './i18n'

const app = createApp(App)

app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(VueCookies)

// setup FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faMastodon } from '@fortawesome/free-brands-svg-icons'
library.add(faTwitter, faMastodon)

setupI18n().then(() => {
  app.mount('#app')
})

// app.mount('#app')
