import { createI18n } from 'vue-i18n'
import { useLocaleStore } from './stores/locale'

const messages = {}

const loadLocaleMessages = async (locale) => {
  if (!messages[locale]) {
    const response = await import(`./locale/${locale}.json`)
    messages[locale] = response.default
  }
  return messages[locale]
}

const i18n = createI18n({
  legacy: false,
  locale: 'en', // default locale
  messages: {},
})

export const setupI18n = async () => {
  const localeStore = useLocaleStore()
  const localeMessages = await loadLocaleMessages(localeStore.locale)
  i18n.global.setLocaleMessage(localeStore.locale, localeMessages)
  i18n.global.locale.value = localeStore.locale

  localeStore.$subscribe(async (mutation, state) => {
    const localeMessages = await loadLocaleMessages(state.locale)
    i18n.global.setLocaleMessage(state.locale, localeMessages)
    i18n.global.locale.value = state.locale
  })
}

export default i18n
