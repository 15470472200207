// src/stores/locale.js
import { defineStore } from 'pinia'

export const useLocaleStore = defineStore('locale', {
  state: () => ({
    locale: 'en',
  }),
  actions: {
    setLocale(locale) {
      this.locale = locale
    },
  },
})
